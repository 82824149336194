import axios from 'axios';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';
import { setMintedNFT, setSolanaMintedNFT } from '../actions/Auth';

function* innerFormData({ payload }) {
  const { error, response } = yield call(postCall, { path: '/users/info', payload });
  if (error) EventBus.publish('error', error['response']['data']['message'])
  else if (response) EventBus.publish('success', response['data']['message']);
};


/*========== SOL MINTED NFT's FUNCTIONS =============*/

function* solanaMintNFT({ payload }) {
  const { error, response } = yield call(postCall, `/burnNFT/storeBurnNftData/`, payload);
  if (error) {
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(setSolanaMintedNFT(response['data']['body']));
  }
};

/*========== ETH MINTED NFT's FUNCTIONS =============*/

function* getMintedNFT({ payload }) {
  const { error, response } = yield call(getCall, `/burnNFT/getTokenIds/${payload}`);
  if (error) {
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(setMintedNFT(response['data']['body']));
  }
};

function* actionWatcher() {
  yield takeEvery('INNER_FORM', innerFormData);
  yield takeEvery('GET_MINTED_NFT', getMintedNFT);
  yield takeEvery('GET_SOLANA_MINTED_NFT', solanaMintNFT)
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall(path, payload) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
