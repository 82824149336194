import { PURGE } from "redux-persist";

let initialState = {
  publicAddress: '',
  allMintedNFTs:[],
  solNFT : '',
  setLoader: { message: 'Please Wait...', status: false },
}

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {

    case 'LOGIN':
      // localStorage.setItem('publicAddress', payload);
      return {
        ...state,
        publicAddress: payload,
      };

    case 'LOGOUT':
      // localStorage.removeItem('publicAddress');
      return {
        ...state,
        publicAddress: '',
      };

    case 'TOGGLE_LOADER':
      return {
        ...state,
        setLoader: payload,
      };

    case 'SET_MINTED_NFT':
      return {
        ...state,
        allMintedNFTs: payload
    };

    case 'SET_SOLANA_MINTED_NFT':
      return {
        ...state,
        solNFT: payload
    };
    default:
      return state;
  }
};

export default Auth;