import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import './index.css';
import { web3 } from '../../store/web3';
import { networkId, message } from '../../store/config';
import { login, getMintedNFT, solanaMintNFT, toggleLoader } from '../../store/actions/Auth';

import { BURN, BURNAddress } from '../../store/contract/index';
import { Token, TokenAddress } from '../../store/contract/index';
import axios from 'axios';
const base58Regex = /^[1-9A-HJ-NP-Za-km-z]+$/;

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            address: '',
            isShareModal: false,
            isShareModalTx:false,
            selected: null,
            tokenid: null,
            walletPhantom: null,
            approveCheck: false,
            status:false,
            loading: 0,
            nftUrl: [],
            ethBalanceCheck:false,
            nftMetadata :[],
            solNFTURL:null,
            burnTx:false,
        };
    };


    async componentDidMount() {
        let { publicAddress } = this.props;
        this.setState({ address: publicAddress });
    };

    componentWillReceiveProps({ allMintedNFTs, solNFT}) {

        if (allMintedNFTs) this.getImage(allMintedNFTs)
        if (solNFT) this.setState({solNFTURL:solNFT, isShareModalTx:true})
        if (solNFT && this.state.burnTx) {
            this.props.toggleLoader({ message: 'Bridging in Progress...', status: false });
            EventBus.publish('success', `NFT Bridging successfully`)
        }
    }

    connectWallet = async () => {
        if (typeof window.ethereum === 'undefined') {
            EventBus.publish('error', `Please Install Metamask!!!`);
            return;
        }
        web3.eth.net.getId(async (err, netId) => {
            if (netId != networkId) {
                EventBus.publish('info', message);
                return;
            }
            let address = (await web3.currentProvider.enable())[0];
            this.props.login(address);
            this.setState({ address });
        });
    };

    handleCheckboxChange = async (index, tokenID) => {
        if (this.state.selected === index) this.setState({ tokenid: null, selected: null, approveCheck: false });
        else {
            let approveChecking = await Token.methods.getApproved(tokenID).call();
            (approveChecking === BURNAddress) ? this.setState({ approveCheck: true }) : this.setState({ approveCheck: false })
            this.setState({ tokenid: tokenID, selected: index });
        }
    }

    handlePhantomWallet(wallet) {
        if (this.state.walletPhantom === wallet) this.setState({ walletPhantom: wallet });
        else this.setState({ walletPhantom: wallet });
        
    }

    handleButtonClick = async() => {

            if (this.state.address !== '') {
                const balanceWei = await web3.eth.getBalance(this.state.address);
                const balanceEth = web3.utils.fromWei(balanceWei, 'ether');

                if (balanceEth > 0) this.setState({ethBalanceCheck : false}) 
                else this.setState({ethBalanceCheck : true})
                
                this.toggleShareModal()
                this.setState({loading: 1})
                this.props.getMintedNFT(this.state.address)
            }else {return EventBus.publish('error','Please Connect Wallet!!!')}
         
    }

    getImage = async (nftData) => {
        this.setState({nftMetadata:[]})
        if (nftData.length === 0) this.setState({ loading: 0 });
        else {
            nftData.map(async(data)=>{
                await axios.get(data.tokenUri).then((res)=>{
                    this.setState(prevState => ({
                        nftMetadata: [...prevState.nftMetadata, { 
                            imageUrl: res.data.image, 
                            tokenId: data.tokenid 
                        }]
                    }))
 
                });
            })
            this.setState({ loading: 0 });
        }
    }

    burnNFT = async () => {

        if (!this.state.address) return EventBus.publish('info', 'Please connect your eth wallet');
        if (!this.state.walletPhantom) return EventBus.publish('info', 'Enter your phantom wallet');
        if (!this.state.tokenid) return EventBus.publish('info', 'Please select your NFT');
        if (!base58Regex.test(this.state.walletPhantom)) return EventBus.publish('info', 'Incorrect Phantom Wallet Address');

        this.props.toggleLoader({ message: 'Bridging in Progress...', status: true });
        try {
            await web3.eth.sendTransaction({
                from: this.state.address,
                to: BURNAddress,
                data: BURN.methods.burn(this.state.walletPhantom, this.state.tokenid).encodeABI(),
            }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
                .on('receipt', async (receipt) => {
                    if (receipt.status) this.props.solanaMintNFT({ nftOwner: this.state.address, solanaMintingAddress: this.state.walletPhantom, tokenId: this.state.tokenid, ethTransactionHash:receipt['transactionHash']})
                    this.setState({ walletPhantom: null,selected: null,tokenid: null,approveCheck: false, burnTx:true})
                    this.props.getMintedNFT(this.state.address)
                    // this.props.toggleLoader({ message: 'Bridging in Progress...', status: false });
                    
                })
        } catch (e) {
            console.log('********Error = ', e);
            this.props.toggleLoader({ message: 'Bridging not Completed...', status: false });
            EventBus.publish('error', `Unable to Bridge NFT`);
        };
    };

    approveNFT = async () => {

        if (!this.state.address) return EventBus.publish('info', 'Please connect your eth wallet');
        if (!this.state.tokenid) return EventBus.publish('info', 'Please select your NFT');


        this.props.toggleLoader({ message: 'Approve in Progress...', status: true });
        try {
            await web3.eth.sendTransaction({
                from: this.state.address,
                to: TokenAddress,
                data: Token.methods.approve(BURNAddress, this.state.tokenid).encodeABI(),
            }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
                .on('receipt', async (receipt) => {
                    this.props.toggleLoader({ message: 'Approve in Progress...', status: false });
                    EventBus.publish('success', `NFT Approved successfully`);
                    this.setState({approveCheck: true})
                })
        } catch (e) {
            console.log('********Error = ', e);
            this.props.toggleLoader({ message: 'Approve not Completed...', status: false });
            EventBus.publish('error', `Unable to Approve NFT`);

        };
    };

    toggleShareModalTx = () => {
        this.props.getMintedNFT(this.state.address)
        this.setState({ isShareModalTx: !this.state.isShareModalTx , burnTx: false })
    }


    toggleShareModal = () => this.setState({ isShareModal: !this.state.isShareModal })

    render() {
        let { isShareModal, walletPhantom, selected, loading, nftMetadata, ethBalanceCheck, isShareModalTx ,solNFTURL, burnTx} = this.state;
        let { sticky, publicAddress} = this.props;

        return (
            <div className={`mp-club-nav ${sticky && 'sticky-nav'}`}>
                <nav className='navbar navbar-expand-lg sidenav' id="sidenav-1" data-mdb-hidden="false">
                    <div className='container-fluid'>
                        <div className="inner-container">
                            {/* <Link className='navbar-brand' to='/'><img src={require('../../static/images/landing/logo.png')} alt='' /></Link> */}
                            <button onClick={() => this.setState({ isOpen: true })} className='navbar-toggler' type='button' data-toggle='collapse'
                                data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false'
                                aria-label='Toggle navigation' aria-haspopup="true">
                                <i class='fa fa-bars' aria-hidden='true'></i>
                            </button>
                            <div className='collapse navbar-collapse nav-links' id='navbarSupportedContent'>
                                <ul className='navbar-nav sidenav-menu'>
                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#about'>About</HashLink>
                                    </li>
                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#roadmap'>Roadmap</HashLink>
                                    </li>
                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#nft'>NFT Gallery</HashLink>
                                    </li>

                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#team'>Team</HashLink>
                                    </li>
                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#mint-your-nft-in-three-steps'>How to Mint</HashLink>
                                    </li>
                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#faqs'>FAQs</HashLink>
                                    </li>
                                    <li className='nav-item'>
                                        <a href='https://white-paper-poker-club.s3.us-east-2.amazonaws.com/Whitepaper-MPC.pdf' className='nav-link' target="_blank">Whitepaper</a>
                                    </li>
                                    <li className='nav-item'>
                                        <button className='nav-link' onClick={this.handleButtonClick}>Bridge</button>
                                    </li>
                                    <button onClick={() => this.connectWallet()} className="nav-btn btn-style-one desktop-btn" type="button">
                                        {publicAddress == '' || publicAddress == null
                                            ? 'Connect wallet'
                                            : publicAddress && publicAddress.substring(0, 5) + '.....' + publicAddress.substring(37, publicAddress.length)
                                        }
                                    </button>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>

                {/* --------------- Bridge MODAL--------------- */}
                {solNFTURL && burnTx ? 
                    <Modal size="lg" isOpen={isShareModalTx} toggle={this.toggleShareModalTx} className={`main-modal bridge-modals`}>
                    <ModalHeader toggle={this.toggleShareModalTx}></ModalHeader>
                    <ModalBody>
                        <div className='content-area'>
                            <div className="top-area">
                                <div className="logo-area">
                                    <img src={require('../../static/images/landing/logo.png')} alt='' />
                                </div>
                                <div className="right-area">
                                    <img src={require('../../static/images/fish-modal.png')} alt='' />
                                </div>
                            </div>
                            <div className="bridge-section">
                                <div className="top-area">
                                    <h2>TRANSACTION</h2>
                                </div>
                                <div className="nft-selection-area">
                                <h1 className="text-white">SUCCESS</h1> 
                                <label className="text-center">
                                    <a href={`https://explorer.solana.com/address/${solNFTURL}?cluster=devnet`}  target="_blank" rel="noopener noreferrer">{solNFTURL}</a>
                                </label>
                            </div>
                            </div>
                        </div>
                    </ModalBody>
                    </Modal> : <Modal size="lg" isOpen={isShareModal} toggle={this.toggleShareModal} className={`main-modal bridge-modals`}>
                    <ModalHeader toggle={this.toggleShareModal}></ModalHeader>
                    <ModalBody>
                        <div className='content-area'>
                            <div className="top-area">
                                <div className="logo-area">
                                    <img src={require('../../static/images/landing/logo.png')} alt='' />
                                </div>
                                <div className="right-area">
                                    <img src={require('../../static/images/fish-modal.png')} alt='' />
                                </div>
                            </div>
                            <div className="bridge-section">
                                <div className="top-area">
                                    <h2>BRIDGE</h2>
                                </div>
                                <div className="wallet-area">
                                    <div className="group-from">
                                        <label>Enter Phantom Wallet</label>
                                        <input type="text" name="wallet" placeholder="Enter Phantom Wallet" onChange={(e) => this.handlePhantomWallet(e.target.value)} value={walletPhantom} />
                                    </div>
                                </div>


                                <div className="nft-selection-area"> 
                                {
                                    loading === 1 ?<div className="no-nft-container">
                                        <h1 className="text-white">LOADING...</h1>
                                    </div>: <>
                                    <div className="text-center">
                                        <h4 >TOTAL NFT : <span>{nftMetadata ? nftMetadata.length  : 0}</span></h4>  
                                    </div>
                             
                                    { 
                                        
                                        nftMetadata.length === 0 ? 
                                        <div className="no-nft-container">
                                            <h1 className="text-white">No NFT</h1>
                                        </div> : ethBalanceCheck ? <div className="no-nft-container">
                                            <h1 className="text-white">you have {nftMetadata.length} nfts, but insufficient gas fees to complete the transaction.</h1>
                                        </div> : <>
                                        <div className="nft-inner-area">      
                                                                         
                                            {nftMetadata.map((data, index) => (           
                                            <div key={index} className="nft-box"> 
                                                <input
                                                    type="checkbox"
                                                    name="nft"
                                                    id={`myCheckbox${index}`}
                                                    checked={selected === index}
                                                    onChange={() => this.handleCheckboxChange(index, data.tokenId)}
                                                />
                                                <label htmlFor={`myCheckbox${index}`}><img src={data.imageUrl} alt='' /></label>
                                            </div>)) }                                       
                                        </div> 
                                        <div className="btn-area">
                                        {
                                            this.state.approveCheck ? (
                                                <button className="btn-style-two" onClick={this.burnNFT}>Confirm</button>
                                            ) : (
                                                <button className="btn-style-two" onClick={this.approveNFT}>Approve</button>
                                            )
                                        }
                                        </div> 
                                        </>
                                    }
                                    </>
                                }   
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    </Modal>
                }
            </div >
        );
    }
}

const mapDispatchToProps = {
    login, getMintedNFT, solanaMintNFT, toggleLoader
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, allMintedNFTs , solNFT } = Auth;
    return { publicAddress, allMintedNFTs, solNFT }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);