import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Countdown from 'react-countdown';
import OwlCarousel from 'react-owl-carousel';
import React, { Component, Fragment } from 'react';
import { validate } from 'wallet-address-validator';
import { HashLink } from 'react-router-hash-link';

import './index.css';
import '../../static/css/animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';

import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { networkId, message, opensea } from "../../store/config";
import { toggleLoader } from "../../store/actions/Auth";
import { ICO, ICOAddress } from '../../store/contract/index';


class MPokerClub extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stage: 0,
            sticky: false,
            isNetwork: true,
            isPreSale: false,
            rate: '',
            nftPrice: '',
            quantity: 0,
            max: '',
            min: 0,
            mintItems: '',
            availableItems: '',
            contributions: '',
            openingTime: '',
            closingTime: '',
            address: localStorage.getItem('publicAddress'),
        };
    };

    async componentDidMount() {
        let { publicAddress } = this.props;
        // this.setState({ address: publicAddress }, () => this.checkEthereum());
        // this.checkEthereum();
    };

    checkEthereum = async () => {
        let { address } = this.state;
        if (typeof window.ethereum !== "undefined") {
            // check network
            web3.eth.net.getId((err, netId) => {
                if (netId != networkId) this.setState({ isNetwork: false });
                else this.setState({ isNetwork: true });
            });
            let rate = await (ICO.methods.rate().call());
            let cap = await (ICO.methods.cap().call());
            let minted = await (ICO.methods.minted().call());
            let maximum = await (ICO.methods.investorHardCap().call());
            let time = await (ICO.methods.openingTime().call());
            let closingTime = await (ICO.methods.closingTime().call());
            let stage = await (ICO.methods.stage().call());
            let nftPrice = await (web3.utils.fromWei(rate.toString(), 'ether'));
            let availableItems = parseFloat(cap);
            let mintItems = parseFloat(minted);
            let openingTime = parseFloat(time);
            let max = parseFloat(maximum);
            if (address !== '' && address !== null && address !== undefined) {
                let contribution = await (ICO.methods.contributions(address).call());
                let contributions = parseFloat(contribution);
                this.setState({ contributions });
            };
            this.setState({ stage, rate, nftPrice, max, openingTime, closingTime, availableItems, mintItems });
        } else this.setState({ metamaskNull: true });
    };

    componentWillReceiveProps({ publicAddress }) {
        this.setState({ address: publicAddress });
    };

    increaseQuantity = async () => {
        let { address, quantity, max, contributions } = this.state;
        if (address == '' || address == null) {
            EventBus.publish('error', `Connect to Metamask`);
            return;
        };
        let limit = await (parseFloat(quantity) + parseFloat(contributions));
        if (limit < max) {
            quantity = parseFloat(quantity) + 1;
            this.setState({ quantity });
        }
        else EventBus.publish('info', `You can't exceed quantity then maximum limit of ${max}`);
    };

    decreaseQuantity = () => {
        let { address, quantity, min } = this.state;
        if (address == '' || address == null) {
            EventBus.publish('error', `Connect to Metamask`);
            return;
        };
        if (quantity > min) {
            quantity = parseFloat(quantity) - 1;
            this.setState({ quantity });
        }
        else EventBus.publish('info', `You can't decrease quantity to minimum`);
    };

    maxQuantity = async () => {
        let { address, quantity, max, contributions } = this.state;
        if (address == '' || address == null) {
            EventBus.publish('error', `Connect to Metamask`);
            return;
        };
        let limit = await (parseFloat(max) - parseFloat(contributions));
        if (quantity < limit) {
            let quantity = parseFloat(max) - parseFloat(contributions);
            this.setState({ quantity })
        }
        else EventBus.publish('info', `You already have maximum limit of quantity`);
    };

    handleQuantity = (number) => {
        let { address, quantity, min, max, contributions } = this.state;
        if (address == '' || address == null) {
            EventBus.publish('error', `Connect to Metamask`);
            return;
        };
        let value = parseFloat(number);
        if (quantity > 0) this.setState({ quantity: 0 });

        if (value > min && value <= max) {
            let limit = (value + parseFloat(contributions));
            if (limit <= max) this.setState({ quantity: value });
            else EventBus.publish('info', `You can't exceed quantity then maximum limit of ${max}`);
        }
        else {
            if (value > max) EventBus.publish('info', `You are exceeding max amount of ${max}`);
        }
    };

    mintNFT = async () => {
        try {
            let { stage, rate, quantity, address, availableItems } = this.state;

            if (address == '' || address == null) {
                EventBus.publish('error', `Connect to Metamask`);
                return;
            };
            if (!validate(address, 'ETH')) {
                EventBus.publish('error', `Please provide valid ETH Address`);
                return;
            };
            if (quantity == 0) {
                EventBus.publish('error', `Please Enter the Quantity`);
                return;
            };

            const value = rate * quantity;
            const from = (await web3.currentProvider.enable())[0];
            const balance = await web3.eth.getBalance(from);
            const mintItems = await (ICO.methods.minted().call());

            if (mintItems >= availableItems) {
                EventBus.publish('error', `Sale has been Ended`);
                return;
            };

            if (value > balance) {
                EventBus.publish('error', `Your have insufficient ETH Balance`);
                return;
            };

            /** Mint NFT **/
            this.props.toggleLoader({ message: 'Minting in Progress...', status: true });
            await web3.eth.sendTransaction({
                from,
                value,
                to: ICOAddress,
                data: ICO.methods.buyNFT(address, quantity).encodeABI(),
            }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
                .on('receipt', async (receipt) => {
                    const mintItems = await (ICO.methods.minted().call());
                    const contributions = await (ICO.methods.contributions(address).call());
                    let time = await (ICO.methods.openingTime().call());
                    let openingTime = parseFloat(time);

                    this.setState({ mintItems, contributions, quantity: 0, openingTime, isPreSale: false });
                    this.props.toggleLoader({ status: false });
                    EventBus.publish('success', `NFT Minted successfully`);
                })
        } catch (e) {
            console.log('********Error = ', e);
            this.props.toggleLoader({ message: 'Minting not Completed...', status: false });
            EventBus.publish('error', `Unable to Mint NFT`);
        };
    };

    handleReload = () => {
        // this.setState({ isPreSale: true }, () => this.checkEthereum());
        EventBus.publish('info', "Sale is Started");
    };

    renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // this.setState({ isPreSale: true }, () => this.checkEthereum());
            return <h2 className="text-white">Sale is Started</h2>;
        }
        else {
            return (
                <div className="countdown-timer-time">
                    <div className="counter-day counter-days">
                        <h5>{days ? days : '-'}</h5>
                        <p>Days</p>
                    </div>
                    <div className="counter-day counter-hour">
                        <h5>{hours ? hours : '-'}</h5>
                        <p>Hrs</p>
                    </div>
                    <div className="counter-day counter-minute">
                        <h5>{minutes ? minutes : '-'}</h5>
                        <p>Mins</p>
                    </div>
                    <div className="counter-day counter-seconds">
                        <h5>{seconds ? seconds : '-'}</h5>
                        <p>Sec</p>
                    </div>
                </div>
            );
        }
    };

    onScroll = () => {
        const { pageYOffset } = window;
        if (pageYOffset > 20) this.setState({ sticky: true });
        if (pageYOffset < 20) this.setState({ sticky: false });
    };

    render() {

        const owl_option = {
            margin: 20,
            nav: true,
            items: 1,
            loop: true,
            autoplay: true,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };

        let { address, isNetwork, isPreSale, quantity, nftPrice, mintItems, availableItems, openingTime,
            metamaskNull, max, contributions, sticky } = this.state;

        return (
            <div className="mp-club-page" onWheel={this.onScroll}>
                <Navbar sticky={sticky} />
                {/* Banner Section */}
                <section id="home" className="banner-sec" style={{ backgroundImage: `url(${require("../../static/images/landing/banner-right.png")})` }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="banner-text col-lg-6 col-md-12">
                                <div className="banner-inner">
                                    <div className="title-icon">
                                        <div className="text-box">
                                            <h1>Enter the Pokerverse </h1>
                                        </div>
                                        <div className="img-box">
                                            <img className="left-img" src={require('../../static/images/landing/logo-banner.png')} alt='' />
                                        </div>

                                    </div>
                                    {/* <p>Join the exclusive Poker Club and be a part of this exciting new journey.</p> */}
                                    <div className="countdown-timer timer-coutdwon">
                                        {!isPreSale &&
                                            <div className="timer-inner">
                                                {openingTime &&
                                                    <Countdown date={Date.now() + parseInt((openingTime * 1000) - Date.now())} renderer={this.renderer} onComplete={this.handleReload} />
                                                }
                                                {/* <Countdown date={Date.now() + parseInt((1657945710 * 1000) - Date.now())} renderer={this.renderer} onComplete={this.handleReload} /> */}
                                            </div>
                                        }
                                    </div>

                                    <div className="btn-group">
                                        <HashLink className='btn-style-two' smooth to='/#mint'>Mint now</HashLink>
                                        <a href="https://discord.gg/PqqdrwTxFn" className='btn-style-two' target="_blank">JOIN OUR DISCORD TO GET STARTED</a>
                                    </div>

                                    <div className="text-box">
                                        <h4>MINTING CALENDAR</h4>
                                        <p>MINT 1 | 22nd Jul | 400 available | 0.15 ETH<br />
                                            MINT 2 | 29th Jul | 400 available | 0.16 ETH<br />
                                            MINT 3 | 5th Aug | 400 available | 0.17 ETH<br />
                                            MINT 4 | 12th Aug |  400 available | 0.18 ETH<br />
                                            MINT 5 | 19th Aug | 400 available | 0.19 ETH<br />
                                            MINT 6 | 26th Aug | All remaining | 0.20 ETH</p>
                                    </div>
                                </div>
                            </div>
                            <div className="banner-img col-lg-6 col-md-12">
                                <div className="img-inner">
                                    <img src={require('../../static/images/landing/banner-img.png')} alt='' />
                                    <div className="btn-group">
                                        <a href="https://opensea.io/collection/modernpokerclub" className="social-link opensea-link" target="_blank"><img src={require('../../static/images/landing/opensea.png')} alt='' /><span>MPC Collection</span></a>
                                        <a href="https://twitter.com/modernpokerclub" className="social-link" target="_blank"><img src={require('../../static/images/landing/twitter.png')} alt='' /><span>Twitter</span></a>
                                        <a href="https://discord.gg/PqqdrwTxFn" className="social-link" target="_blank"><img src={require('../../static/images/landing/discord.png')} alt='' /><span>Discord</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* About Section */}
                <section id="about" className="about-sec">
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title">
                                    <h2>About</h2>
                                </div>
                            </div>
                            <div className="video-block col-lg-8 offset-lg-2 col-md-12">
                                <div className="video-inner">
                                    <video loop="true" controls="true" preload="auto" webkit-playsinline="true" playsinline="true">
                                        <source src="https://mpc-intro-video.s3.us-east-2.amazonaws.com/mpc-about-second-video.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="about-block">
                                    <div className="about-inner">
                                        <p>Sharks, Fish, Donkeys, and Whales. The four factions lived together in harmony. Then, everything changed when the Shark faction attacked. What faction will you be? Let the mint choose your fate.</p>
                                        <p>Discover a new style of poker with Modern Poker Club’s evolving brand of faction play, where you’ll strategize with faction mates to conquer your opponents, adorn the faction crown and reap the rewards.</p>
                                        <p>Within our Discord lies your faction HQ - a hive where veterans help newer players learn, where schemes are hatched and where collaboration meets fierce competition.</p>
                                        <p>Play casually or competitively. Either way, you will experience great moments, make new poker buddies and be a part of the future of Web 3 poker.</p>
                                        <p>GLHF, see you on the felt!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                {/* About Section */}
                <section id="benifits" className="benifits-sec">
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title">
                                    <h2>BENEFITS</h2>
                                </div>
                            </div>
                            <div className="video-block col-lg-8 offset-lg-2 col-md-12">
                                <div className="video-inner">
                                    <video loop="true" controls="true" preload="auto" webkit-playsinline="true" playsinline="true">
                                        <source src="https://assets-poker-club.s3.us-east-2.amazonaws.com/mpc-about-video.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="benifits-img">
                                    <img className="desktop" src={require('../../static/images/landing/mpc-benefits.png')} alt='' />
                                    <img className="mobile" src={require('../../static/images/landing/mpc-benefits-mobile-comp.png')} alt='' />
                                </div>
                            </div>


                        </div>
                    </div>
                </section >

                {/* Roadmap Section */}
                < section id="roadmap" className="roadmap" style={{ backgroundImage: `url(${require("../../static/images/landing/roadmap-bg.png")})` }
                }>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title">
                                    <h2>JOURNEY TO THE POKERVERSE</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="timeline">
                                    <div className="timeline-container left active wow fadeInUp" data-wow-delay="0.3s">
                                        <h1>1</h1>
                                        <div className="content">
                                            <div className="inner-timeline">
                                                <div className="img-box">
                                                    <img src={require('../../static/images/landing/roadmpap-1.png')} alt='' />
                                                </div>
                                                <div className="text-box">
                                                    <h2>PRE-FLOP [Completed]</h2>
                                                    <ul>
                                                        <li>Hosting of daily poker tournaments & ring games</li>
                                                        <li>Passive earnings from faction tournaments</li>
                                                        <li>3,000  NFTs with over 200 unique traits</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="timeline-container right wow fadeInUp" data-wow-delay="0.6s">
                                        <h1>2</h1>
                                        <div className="content">
                                            <div className="inner-timeline">
                                                <div className="img-box">
                                                    <img src={require('../../static/images/landing/roadmpap-2.png')} alt='' />
                                                </div>
                                                <div className="text-box">
                                                    <h2>FLOP [In-Progress]</h2>
                                                    <ul>
                                                        <li>Major in-real-life marketing partnership</li>
                                                        <li>Modern Poker Club’s own 2D poker platform with web 3 compatibility</li>
                                                        <li>Development of unique faction-play mechanics</li>
                                                        <li>Hosting of multi-project tournaments with NFT giveaways</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="timeline-container left wow fadeInUp" data-wow-delay="0.9s">
                                        <h1>3</h1>
                                        <div className="content">
                                            <div className="inner-timeline">
                                                <div className="img-box">
                                                    <img src={require('../../static/images/landing/roadmpap-3.png')} alt='' />
                                                </div>
                                                <div className="text-box">
                                                    <h2>TURN [Coming Up]</h2>
                                                    <ul>
                                                        <li>Giveaway of rare gold NFTs to random early minters</li>
                                                        <li>Launch of Modern Poker Club’s governance token</li>
                                                        <li>Integration into NFT-rarity tracking sites</li>
                                                        <li>Mutant breeding</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="timeline-container right wow fadeInUp" data-wow-delay="1.2s">
                                        <h1>4</h1>
                                        <div className="content">
                                            <div className="inner-timeline">
                                                <div className="img-box">
                                                    <img src={require('../../static/images/landing/roadmpap-4.png')} alt='' />
                                                </div>
                                                <div className="text-box">
                                                    <h2>RIVER [Planned]</h2>
                                                    <ul>
                                                        <li>Modern Poker Club’s own poker reality entertainment show</li>
                                                        <li>Giveaway of branded 3D resin miniature card protectors</li>
                                                        <li>Launch of poker training courses</li>
                                                        <li>Modern Poker Club merch store</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="timeline-container left  wow fadeInUp" data-wow-delay="1.5s">
                                        <h1>5</h1>
                                        <div className="content">
                                            <div className="inner-timeline">
                                                <div className="img-box">
                                                    <img src={require('../../static/images/landing/roadmpap-5.png')} alt='' />
                                                </div>
                                                <div className="text-box">
                                                    <h2>SHOWDOWN [Long-Term Vision]</h2>
                                                    <ul>
                                                        <li>Interactive 3D gaming ecosystem built on major metaverse platform</li>
                                                        <li>Highest quality poker-in-the-metaverse experience on own pokerverse</li>
                                                        <li>VR integration</li>
                                                        <li>Grand opening of physical card room HQ in Texas</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    {/* NFT Section */}
                    <div id="nft" className="nft-sec">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="sec-title text-center">
                                        <h2>NFT Gallery</h2>
                                    </div>
                                </div>
                                <div className="inner-container">
                                    <OwlCarousel className="slider-items owl-carousel ltf-owl owl-theme" autoplaySpeed={3000}  {...owl_option} nav>
                                        <div className="item">
                                            <div className="nft-img-box">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <img src={require('../../static/images/landing/nft-1.png')} alt='' />
                                                        <img src={require('../../static/images/landing/nft-2.png')} alt='' />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <img src={require('../../static/images/landing/nft-3.png')} alt='' />
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <img src={require('../../static/images/landing/nft-4.png')} alt='' />
                                                        <img src={require('../../static/images/landing/nft-4-1.png')} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className="nft-img-box">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <img src={require('../../static/images/landing/nft-5.png')} alt='' />
                                                    </div>

                                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <img src={require('../../static/images/landing/nft-7.png')} alt='' />
                                                        <img src={require('../../static/images/landing/nft-8.png')} alt='' />
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <img src={require('../../static/images/landing/nft-6.png')} alt='' />
                                                        <img src={require('../../static/images/landing/nft-6-1.png')} alt='' />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className="nft-img-box">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <img src={require('../../static/images/landing/nft-9.png')} alt='' />
                                                        <img src={require('../../static/images/landing/nft-10.png')} alt='' />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <img src={require('../../static/images/landing/nft-11.png')} alt='' />
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <img src={require('../../static/images/landing/nft-12.png')} alt='' />
                                                        <img src={require('../../static/images/landing/nft-12-1.png')} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className="nft-img-box">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <img src={require('../../static/images/landing/nft-13.png')} alt='' />
                                                        <img src={require('../../static/images/landing/nft-14.png')} alt='' />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <img src={require('../../static/images/landing/nft-15.png')} alt='' />
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <img src={require('../../static/images/landing/nft-19.png')} alt='' />
                                                        <img src={require('../../static/images/landing/nft-18.png')} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                {/* About Section */}
                <section className="about-sec mpc-sec" style={{ backgroundImage: `url(${require("../../static/images/landing/roadmap-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="sec-title text-center">
                                    <h2>GOVERNANCE TOKEN</h2>
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-2 col-md-12">
                                <div className="about-block">
                                    <div className="about-inner">
                                        <h4>Planned Modern Poker Club Token (GTO - Game Theory Optimal):</h4>
                                        <p>GTO is the planned governance token that will underpin the Modern Poker Club ecosystem. It will be freely traded on crypto exchanges and offer a means of trading into and out of the economy. It will also form the basis of a future DAO (Decentralized Autonomous Organization), which will impart voting rights onto holders.</p>
                                        <p>These tokens will be exchangeable throughout the Modern Poker Club ecosystem for a variety of uses, such as:</p>
                                        <ul>
                                            <li>purchases within the Modern Poker Club store</li>
                                            <li>enrolling in poker coaching courses​ </li>
                                            <li>NFT breeding</li>
                                            <li>NFT burning</li>
                                            <li>and more.</li>
                                        </ul>
                                        <p>​GTO will be obtained by redeeming MPC chips or through a crypto exchange.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="img-box style-3">

                                </div>
                            </div>

                        </div>
                    </div>
                </section >



                {/* Tokenomics Section */}
                {/* < section className="tokenomics sec" style={{ backgroundImage: `url(${require("../../static/images/landing/toknomics-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title text-center">
                                    <h2>Tokenomics</h2>
                                    <span className="coming-soon">Coming soon</span>
                                    <p>Stake your NFT to earn tokens (Incoming)</p>

                                </div>
                            </div>
                            <div className="tokenomics-block col-lg-3 col-md-6 col-sm-12">
                                <div className="tokenomics-inner">
                                    <div className="image-box">
                                        <img src={require('../../static/images/landing/tokenomics-1.png')} alt='' />
                                    </div>
                                    <div className="textbox-tokenomics">
                                        <h4>own your nft</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="tokenomics-block col-lg-3 col-md-6 col-sm-12">
                                <div className="tokenomics-inner">
                                    <div className="image-box">
                                        <img src={require('../../static/images/landing/tokenomics-2.png')} alt='' />
                                    </div>
                                    <div className="textbox-tokenomics">
                                        <h4>stake your nft</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="tokenomics-block col-lg-3 col-md-6 col-sm-12">
                                <div className="tokenomics-inner">
                                    <div className="image-box">
                                        <img src={require('../../static/images/landing/tokenomics-3.png')} alt='' />
                                    </div>
                                    <div className="textbox-tokenomics">
                                        <h4>earn rewards</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="tokenomics-block col-lg-3 col-md-6 col-sm-12">
                                <div className="tokenomics-inner">
                                    <div className="image-box">
                                        <img src={require('../../static/images/landing/tokenomics-4.png')} alt='' />
                                    </div>
                                    <div className="textbox-tokenomics">
                                        <h4>PokerVerse coming soon</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section > */}

                {/* Team Section */}
                < section id="team" className="team-sec" >
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title text-center">
                                    <h2>Meet the team</h2>
                                    <p>Experienced and professional team</p>
                                </div>
                            </div>

                            <div className="team-block col-lg-4 col-md-6 col-sm-12">
                                <div className="team-inner">
                                    <div className="image-box">
                                        <img src={require('../../static/images/landing/team-1.png')} alt='' />
                                    </div>
                                    <div className="textbox-team">
                                        <h4>StickyRice1</h4>
                                        <ul className="list">
                                            <li>THE BLUFFER</li>
                                            <li>|</li>
                                            <li><a href="https://twitter.com/TheStickyRice1" target="_blank"><img src={require('../../static/images/landing/twitter-team.png')} alt='' /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="team-block col-lg-4 col-md-6 col-sm-12">
                                <div className="team-inner">
                                    <div className="image-box">
                                        <img src={require('../../static/images/landing/team-9.png')} alt='' />
                                    </div>
                                    <div className="textbox-team">
                                        <h4>Thehodlfodl</h4>
                                        <ul className="list">
                                            {/* <li>WICKED WHALE</li>
                                            <li>|</li> */}
                                            <li>THE LOOSE CANNON</li>
                                            <li>|</li>
                                            <li><a href="https://twitter.com/thehodlfodler" target="_blank"><img src={require('../../static/images/landing/twitter-team.png')} alt='' /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="team-block col-lg-4 col-md-6 col-sm-12">
                                <div className="team-inner">
                                    <div className="image-box">
                                        <img src={require('../../static/images/landing/team-11.png')} alt='' />
                                    </div>
                                    <div className="textbox-team">
                                        <h4>Itpfreak</h4>
                                        <ul className="list">
                                            {/* <li>SHADY SHARK</li>
                                            <li>|</li> */}
                                            <li>THE NIT</li>
                                            <li>|</li>
                                            <li><a href="https://twitter.com/itpfreak" target="_blank"><img src={require('../../static/images/landing/twitter-team.png')} alt='' /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="team-block col-lg-4 offset-lg-4 col-md-6 col-sm-12">
                                <div className="team-inner">
                                    <div className="image-box">
                                        <img src={require('../../static/images/landing/team-10.png')} alt='' />
                                    </div>
                                    <div className="textbox-team">
                                        <h4>Gilbert15</h4>
                                        <ul className="list">
                                            {/* <li>FLAKY FISH</li>
                                            <li>|</li> */}
                                            <li>THE TABLE TALKER</li>
                                            <li>|</li>
                                            <li><a href="https://twitter.com/GertCronje3" target="_blank"><img src={require('../../static/images/landing/twitter-team.png')} alt='' /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="team-block col-lg-4 col-md-6 col-sm-12">
                                <div className="team-inner">
                                    <div className="image-box">
                                        <img src={require('../../static/images/landing/team-4.png')} alt='' />
                                    </div>
                                    <div className="textbox-team">
                                        <h4>Nick Vertucci</h4>
                                        <ul className="list">
                                            <li>THE HUSTLER</li>
                                            <li>|</li>
                                            <li><a href="https://twitter.com/NickVertucciNV" target="_blank"><img src={require('../../static/images/landing/twitter-team.png')} alt='' /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="team-block col-lg-4 col-md-6 col-sm-12">
                                <div className="team-inner">
                                    <div className="image-box">
                                        <img src={require('../../static/images/landing/team-.png')} alt='' />
                                    </div>
                                    <div className="textbox-team">
                                        <h4>Ryan Feldman</h4>
                                        <ul className="list">
                                            <li>THE GRINDER </li>
                                            <li>|</li>
                                            <li><a href="https://twitter.com/TheRyanFeldman" target="_blank"><img src={require('../../static/images/landing/twitter-team.png')} alt='' /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section >

                {/* About Section */}
                <section id="mint-your-nft-in-three-steps" className="benifits-sec">
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title">
                                    <h2>MINT YOUR NFT IN 3 STEPS</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="benifits-img">
                                    <img className="desktop" src={require('../../static/images/landing/mint-nft-3-steps-vert.png')} alt='' />
                                    <img className="mobile" src={require('../../static/images/landing/mpc-minting-mobile-comp.png')} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                {/* Minting Section */}
                < section className="minting-sec" id="mint" style={{ backgroundImage: `url(${require("../../static/images/landing/mint-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                {isNetwork && openingTime &&
                                    <div className="sec-title">
                                        {isPreSale
                                            ? <Fragment>
                                                {mintItems < availableItems
                                                    ? <h2>Minting</h2>
                                                    : <h2>Minted</h2>
                                                }
                                            </Fragment>
                                            : <h2>Launch In</h2>
                                        }
                                    </div>
                                }
                            </div>
                            {isNetwork && !metamaskNull
                                ? <div className={`minting-form col-lg-6 offset-lg-3 col-md-12`}>
                                    {openingTime == ''
                                        ? <div className="countdown-timer">
                                            <h1 className="text-white">Loading!!!</h1>
                                            <br />
                                            <i className="fa fa-spinner fa-pulse loader-mint" aria-hidden="true" />
                                        </div>
                                        : <Fragment>
                                            {isPreSale
                                                ? <Fragment>
                                                    {mintItems < availableItems
                                                        ? <div className="minting-inner">
                                                            <form className="minting">
                                                                {contributions == max
                                                                    ? <div className="countdown-timer">
                                                                        <h4 className="text-white">You have minted maximum quantity of NFT's</h4>
                                                                        <br />
                                                                    </div>
                                                                    : < Fragment>
                                                                        {/* <div className="group-form">
                                                                                <input type="text" name="price" value={`Total availabe NFT’s:`} disabled />
                                                                                <span>{availableItems - mintItems}</span>
                                                                                </div> */}
                                                                        {/* <div className="group-form">
                                                                                <input type="text" name="price" value={`Total minted NFT’s:`} disabled />
                                                                                <span>{mintItems}</span>
                                                                                </div> */}
                                                                        <div className="group-form">
                                                                            <input type="text" name="price" value={`Price per NFT:`} disabled />
                                                                            <span>{nftPrice} ETH</span>
                                                                        </div>
                                                                        <div className="group-form">
                                                                            <input type="text" name="price" value={`Connected Address`} disabled />
                                                                            <span>{address && address.substring(0, 7) + '.....' + address.substring(35, address.length)}</span>
                                                                        </div>
                                                                        <div className="group-form number-form">
                                                                            <input type="number" name="number" onChange={(e) => this.handleQuantity(e.target.value)} value={quantity > 0 && quantity} placeholder="Enter quantity" />
                                                                            <button onClick={this.decreaseQuantity} type="button" className="count-btn minus-btn"> </button>
                                                                            <button onClick={this.increaseQuantity} type="button" className="count-btn plus-btn"> </button>
                                                                            <button onClick={this.maxQuantity} type="button" className="max-btn btn-style-two">Max</button>
                                                                        </div>
                                                                        {/* <div className="group-form">
                                                                                <input type="text" name="Tprice" value={`Total price: ${(nftPrice * quantity).toFixed(2)} ETH`} disabled />
                                                                                </div> */}
                                                                        <div className="group-form">
                                                                            <button onClick={this.mintNFT} className="btn-style-two" type="button">MINT NOW</button>
                                                                        </div>
                                                                    </Fragment>
                                                                }
                                                            </form>
                                                        </div>
                                                        : <div className="countdown-timer">
                                                            <h2 className="text-white">NFT’s Sold!!!</h2>
                                                            <br />
                                                            <a href={`${opensea}collection/`} target="_blank" className="btn-style-two">View on OpenSea</a>
                                                        </div>
                                                    }
                                                </Fragment>
                                                : <div className="countdown-timer timer-coutdwon">
                                                    <div className="timer-inner">
                                                        {openingTime &&
                                                            <Countdown date={Date.now() + parseInt((openingTime * 1000) - Date.now())} renderer={this.renderer} onComplete={this.handleReload} />
                                                        }
                                                        {/* <Countdown date={Date.now() + parseInt((1657929660 * 1000) - Date.now())} renderer={this.renderer} onComplete={this.handleReload} /> */}
                                                    </div>
                                                </div>
                                            }
                                        </Fragment>
                                    }

                                </div>
                                : <div className="minting-form col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12">
                                    <div className="countdown-timer">
                                        <h2 className="text-white">{metamaskNull ? 'Please install metamask extension!' : message}</h2>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </section >

                {/* FAQ's Section */}
                < section id="faqs" className="faqs-sec" style={{ backgroundImage: `url(${require("../../static/images/landing/faqs-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title text-center">
                                    <h2>FAQs</h2>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div id="accordion">
                                    <div class="card">
                                        <div class="card-header" id="headingTwo">
                                            <h5 class="mb-0">
                                                <button class="green btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    How many MPC NFTs are there?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                            <div class="card-body green">
                                                There are 3,000  Modern Poker club tokens for this inaugural collection
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingThree">
                                            <h5 class="mb-0">
                                                <button class="blue btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Why NFTs?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                            <div class="card-body">
                                                NFTs will make ownership frictionless and simple. They will allow us to move digital objects from place to place without needing any centralized structure. A smart wallet that understands digital, physical, and real-world assets will make sure the multi-asset transactions of our tokenized future happen seamlessly.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingFour">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    How long does my modern poker club NFT token last?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                            <div class="card-body">
                                                The MPC token as an art and collectable lasts forever.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingFive">
                                            <h5 class="mb-0">
                                                <button class="green btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    Are all tokens the same?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                            <div class="card-body">
                                                There are four categories of tokens: Shark, Fish, Donkeys and Whales. However all token holders will have an exclusive first experience in the Pokerverse. We will be adding more perks as we progress.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingSix">
                                            <h5 class="mb-0">
                                                <button class="red btn btn-link collapsed" data-toggle="collapse" data-target="#collapsSix" aria-expanded="false" aria-controls="collapsSix">
                                                    Will the NFTs be sold on open marketplaces?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                            <div class="card-body">
                                                Primary sales of ModernPokerClub NFTs will be on modernpokerclub.com . MPC tokens will appear on secondary marketplaces like opensea.io, Immutable X, FTX.US etc. for resale purchase.

                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingSeven">
                                            <h5 class="mb-0">
                                                <button class="red btn btn-link collapsed" data-toggle="collapse" data-target="#collapsSeven" aria-expanded="false" aria-controls="collapsSeven">
                                                    Can I resell my ModernPokerClub NFTs?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                                            <div class="card-body">
                                                Yes. ModernPokerClub tokens can be resold on NFT marketplaces such as opensea.io, Immutable X, FTX.USo etc. If a ModernPokerClub token is resold there will be a 10% royalty fee to ModernPokerClub. ModernPokerClub has pledged to donate 1% of all future ModernPokerClub royalties to charities of token holders choosing

                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingNine">
                                            <h5 class="mb-0">
                                                <button class="red btn btn-link collapsed" data-toggle="collapse" data-target="#collapsNine" aria-expanded="false" aria-controls="collapsNine">
                                                    How much Ethereum do I need to have in my wallet?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordion">
                                            <div class="card-body">
                                                Gas isn't cheap, having .4 ETH should be enough for gas and mint.

                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingTen">
                                            <h5 class="mb-0">
                                                <button class="red btn btn-link collapsed" data-toggle="collapse" data-target="#collapsTen" aria-expanded="false" aria-controls="collapsTen">
                                                    How do I purchase a ModernPokerClub NFT?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordion">
                                            <div class="card-body">
                                                In order to purchase a ModernPokerClub NFT, you will need set-up a Ethereum-based wallet, we recommend Metamask . Having a Metamask wallet gives you access to decentralized apps and a Ethereum address where your NFT will be stored.

                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingeleven">
                                            <h5 class="mb-0">
                                                <button class="red btn btn-link collapsed" data-toggle="collapse" data-target="#collapseleven" aria-expanded="false" aria-controls="collapseleven">
                                                    Are gas fees included in the price of the ModernPokerClub NFT?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseleven" class="collapse" aria-labelledby="headingeleven" data-parent="#accordion">
                                            <div class="card-body">
                                                No. Gas fees are added at the time of purchase and ModernPokerClub doesn’t control these costs or receive any portion of these transaction fees.

                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingTweleve">
                                            <h5 class="mb-0">
                                                <button class="red btn btn-link collapsed" data-toggle="collapse" data-target="#collapsTweleve" aria-expanded="false" aria-controls="collapsTweleve">
                                                    Can I buy ModernPokerClub NFTs with USD or a credit card?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsTweleve" class="collapse" aria-labelledby="headingTweleve" data-parent="#accordion">
                                            <div class="card-body">
                                                No, you need to have ETH in a digital wallet in order to purchase one of ModernPokerClub NFTs

                                            </div>
                                        </div>
                                    </div>


                                    <div class="card">
                                        <div class="card-header" id="headingThirty">
                                            <h5 class="mb-0">
                                                <button class="red btn btn-link collapsed" data-toggle="collapse" data-target="#collapsThirty" aria-expanded="false" aria-controls="collapsThirty">
                                                    How do I get or buy ETH for my Wallet? What about delays and limits?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsThirty" class="collapse" aria-labelledby="headingThirty" data-parent="#accordion">
                                            <div class="card-body">
                                                It's a two step process. First, you must purchase ETH. We recommend using Coinbase, Gemini, Binance. These are platforms you can use to buy ETH. Once you have purchased ETH, you will need to transfer those funds into your metamask wallet in order to buy an NFT token.

                                            </div>
                                        </div>
                                    </div>


                                    <div class="card">
                                        <div class="card-header" id="headingfourtheen">
                                            <h5 class="mb-0">
                                                <button class="red btn btn-link collapsed" data-toggle="collapse" data-target="#collapsfourtheen" aria-expanded="false" aria-controls="collapsfourtheen">
                                                    What is so special about this NFT collection?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsfourtheen" class="collapse" aria-labelledby="headingfourtheen" data-parent="#accordion">
                                            <div class="card-body">
                                                The world’s first MMO P2E poker metaverse. Ability to interact with your favorite live and online poker stars. Collect, trade, and show off the unique NFT collection. The token is the key that unlocks you into the PokerVerse

                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingFifteen">
                                            <h5 class="mb-0">
                                                <button class="red btn btn-link collapsed" data-toggle="collapse" data-target="#collapsFifteen" aria-expanded="false" aria-controls="collapsFifteen">
                                                    What blockchain are these minted on?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsFifteen" class="collapse" aria-labelledby="headingFifteen" data-parent="#accordion">
                                            <div class="card-body">
                                                NFTs are minted and deployed to the Ethereum blockchain.

                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingSixteen">
                                            <h5 class="mb-0">
                                                <button class="red btn btn-link collapsed" data-toggle="collapse" data-target="#collapsSixteen" aria-expanded="false" aria-controls="collapsSixteen">
                                                    Are the NFTs available globally?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsSixteen" class="collapse" aria-labelledby="headingSixteen" data-parent="#accordion">
                                            <div class="card-body">
                                                Yes, they are available in global markets where users have access to crypto wallets
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingSeventeen">
                                            <h5 class="mb-0">
                                                <button class="red btn btn-link collapsed" data-toggle="collapse" data-target="#collapsSeventeen" aria-expanded="false" aria-controls="collapsSeventeen">
                                                    What's the utility of my NFT?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsSeventeen" class="collapse" aria-labelledby="headingSeventeen" data-parent="#accordion">
                                            <div class="card-body">
                                                Owning a NFT is more than owning a PFP (profile pic), it's the key to the Pokerverse. A P2E (play to earn) game in the metaverse. Earn rewards, NFTs, even host your own tournaments and beat your friends, or head to the Pokerverse Clubs and try your luck against the other Whales, Sharks, Fish, and Donkeys. Take your spot atop the Pokerverse Earnings Leaderboard. If you can't beat the house, join it! Own part of the House today by hosting your own private games. Not only can you win tokens against other players, holders will be able to level up their character by just playing, which will grant them rewards and access to different tournaments. Future utility is about to come. Based on the partnerships we'll make your ModernPokerClub NFT unlock more content to other Metaverses, games and so on as we progress.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingTwinty">
                                            <h5 class="mb-0">
                                                <button class="red btn btn-link collapsed" data-toggle="collapse" data-target="#collapsTwinty" aria-expanded="false" aria-controls="collapsTwinty">
                                                    Will the Pokerverse be VR compatible?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsTwinty" class="collapse" aria-labelledby="headingTwinty" data-parent="#accordion">
                                            <div class="card-body">
                                                Arguably, virtual reality is the technology of the future, however, we are very aware as a team it has not been widely adopted across the globe… yet. Argo, we wish not too seriously limit our audience by styling a VR exclusive game, thus the Pokerverse will be launched at first as a game available on PC. It is important to consider, however, that VR integration is absolutely under development and will likely come following the main release of the game. We have, of course, the idea in mind to keep the experience nonetheless, equally as interesting for those playing from their computer of their VR set.

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section >

                <Footer />

            </div >
        );
    }
}

const mapDispatchToProps = { toggleLoader };

const mapStateToProps = ({ Auth }) => {
    let { publicAddress } = Auth;
    return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(MPokerClub);