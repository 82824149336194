const { web3 } = require('../web3');
const { env } = require('../config');

const TokenData = require(`./${env}/Token.json`);
const ICOData = require(`./${env}/ICO.json`);
const BurnData = require(`./${env}/Burn.json`);

const networks = {
  0: 'Disconnected',
  1: 'Mainnet',
  4: 'Rinkeby',
  42: 'Kovan',
  59141 :"Linea Sepolia test network"
}

// let TokenABI, TokenAddress, Token;
const TokenABI = TokenData['abi'];
const TokenAddress = TokenData['address'];
const Token = new web3.eth.Contract(TokenABI, TokenAddress);

let ICO, ICOABI, ICOAddress;
// const ICOABI = ICOData['abi'];
// const ICOAddress = ICOData['address'];
// const ICO = new web3.eth.Contract(ICOABI, ICOAddress);

const BURNABI = BurnData['abi'];
const BURNAddress = BurnData['address'];
const BURN = new web3.eth.Contract(BURNABI, BURNAddress);


module.exports = {
  Token, TokenABI, TokenAddress,
  ICO, ICOABI, ICOAddress,
  BURN , BURNABI, BURNAddress,
};
