
/* -- set app title --*/
const AppTitle = 'FRONTEND MODERN POKER CLUB';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['development'];

/* -- set API URLs --*/
const testing = 'https://dserver.mp-club.io';
const production = 'https://bridgeserver.modernpokerclub.com';
const development = 'https://dbridgeserver.modernpokerclub.com';

let SocketUrl, opensea;
let env = AppMode[0] || 'development', networkId = '', message = '', explorer = '';

switch (AppMode[0]) {
  case 'development':
    networkId = 11155111;
    SocketUrl = development;
    explorer = 'https://sepolia.lineascan.build'
    message = 'Please switch your network to sepolia testnet';
    opensea = 'https://testnets.opensea.io/'
    break;
  case 'production':
    networkId = 1;
    SocketUrl = production;
    message = 'Please switch your network to Ethereum Mainnet';
    explorer = 'https://etherscan.io'
    opensea = 'https://opensea.io/'
    break;
  case 'testing':
    networkId = 11155111;
    SocketUrl = 'http://localhost:4000';
    message = 'Please switch your network to sepolia lineascan testnet';
    explorer = 'https://sepolia.lineascan.build'
    opensea = 'https://testnets.opensea.io/'
    break;
  default:
    networkId = 11155111;
    SocketUrl = 'http://localhost:4000';
    message = 'Please switch your network to sepolia testnet';
    explorer = 'https://sepolia.lineascan.build'
    opensea = 'https://testnets.opensea.io/'
}

let ApiUrl = `${SocketUrl}/api`;
export { AppTitle, ApiUrl, SocketUrl, opensea, networkId, message, explorer, env };