import ReactGA from 'react-ga';
import React, { Component } from 'react';

import './index.css';
import '../../app/MPokerClub/index.css';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        return (
            <div className='footer-mp-club'>
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="footer-widget social-widget">
                                <ul>
                                    <li><a href="https://opensea.io/collection/modernpokerclub" className="social-link opensea-link" target="_blank"><img src={require('../../static/images/landing/opensea.png')} alt='' /></a></li>
                                    <li><a href="https://twitter.com/modernpokerclub" target="_blank"> <i className="icon"><img src={require('../../static/images/landing/footer-twitter.png')} alt='' /></i> </a></li>
                                    <li><a href="https://discord.gg/PqqdrwTxFn" target="_blank"> <i className="icon"><img src={require('../../static/images/landing/footer-discord.png')} alt='' /></i> </a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            <div className="footer-widget copyright-widget">

                                <p>© 2022 Modern Poker Club</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default Footer;