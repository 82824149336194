export const login = (data) => ({
  type: 'LOGIN',
  payload: data,
});

export const logout = (data) => ({
  type: 'LOGOUT',
  payload: data,
});

export const toggleLoader = (data) => ({
  type: 'TOGGLE_LOADER',
  payload: data,
});

export const innerForm = (data) => ({
  type: 'INNER_FORM',
  payload: data,
});


/*========== MINTED NFT's ACTIONS ============= */

export const getMintedNFT = (data) => ({
  type: 'GET_MINTED_NFT',
  payload: data,
});

export const setMintedNFT = (data) => ({
  type: 'SET_MINTED_NFT',
  payload: data
});


export const solanaMintNFT = (data) => ({
  type: 'GET_SOLANA_MINTED_NFT',
  payload: data,
});

export const setSolanaMintedNFT = (data) => ({
  type: 'SET_SOLANA_MINTED_NFT',
  payload: data
});

